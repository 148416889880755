import {isEmpty} from 'lodash';

import INVOICE_TYPE from 'constants/invoiceTypes';
import UNIT from 'constants/units';
import ELECTRONIC_EXPORT_FILING from 'constants/electronicExportFilings';
import COMMON from 'constants/common';

import unitHelper from 'helpers/unitHelper';

import entities from 'domain/entities';

export default {
  mapShipmentDataFromShippingResponse
};

function mapShipmentDataFromShippingResponse(shipment: ShipmentResponse) {
  const origin = shipment.origin;
  const destination = shipment.destination;
  const shipper = shipment.shipper;
  const shipperContact = shipper?.contact;
  const receiver = shipment.receiver;
  const receiverContact = receiver?.contact;
  const international = shipment?.international;

  const eeiFiling = international?.eeiFiling;
  const ITNNumber = eeiFiling?.itn;
  const EINNumber = eeiFiling?.ein;

  let shipmentFrom: Sending = {
    companyName: shipper?.companyName || '',
    contactName: shipperContact?.name || '',
    country: origin?.countryCode || '',
    phoneNumber: shipperContact?.phone?.number || '',
    phoneNumberExtension: shipperContact?.phone?.extension || '',
    email: shipperContact?.email || '',
    address: mapAddress(origin),
    formattedAddress: shipper?.address?.formattedAddress,
    isResidential: shipper?.residential || false,
    isVATIncluded: shipper?.vatNumber ? true : false,
    isTaxIdIncluded: shipper?.taxId ? true : false,
    isEORINumberIncluded: shipper?.eoriNumber ? true : false,
    VATNumber: shipper?.vatNumber || '',
    taxId: shipper?.taxId || '',
    EORINumber: shipper?.eoriNumber || ''
  };

  let shipmentTo: Sending = {
    companyName: receiver?.companyName || '',
    contactName: receiverContact?.name || '',
    country: destination?.countryCode || '',
    phoneNumber: receiverContact?.phone?.number || '',
    phoneNumberExtension: receiverContact?.phone?.extension || '',
    email: receiverContact?.email || '',
    address: mapAddress(destination),
    formattedAddress: receiver?.address?.formattedAddress,
    isResidential: receiver?.residential || false,
    isVATIncluded: receiver?.vatNumber ? true : false,
    isTaxIdIncluded: receiver?.taxId ? true : false,
    isEORINumberIncluded: receiver?.eoriNumber ? true : false,
    VATNumber: receiver?.vatNumber || '',
    taxId: receiver?.taxId || '',
    EORINumber: receiver?.eoriNumber || ''
  };

  let isItnNumberExists: any = undefined;
  if (ITNNumber) isItnNumberExists = true;
  if (!ITNNumber && EINNumber) isItnNumberExists = false;

  let electronicExportFiling = '';
  let foreignTradeRegulations = '';
  if (eeiFiling) {
    if (eeiFiling?.exemption?.id) {
      electronicExportFiling = ELECTRONIC_EXPORT_FILING.OTHER;
      foreignTradeRegulations = eeiFiling?.exemption?.id;
    } else {
      electronicExportFiling = ELECTRONIC_EXPORT_FILING.NO;
    }

    if (eeiFiling?.exemption?.id === ELECTRONIC_EXPORT_FILING.DEFAULT_EXEMPTION) {
      electronicExportFiling = ELECTRONIC_EXPORT_FILING.EXEMPTED;
    }
  }

  let invoiceType = international?.invoiceType;
  let isProformaInvoiceType = invoiceType?.includes(INVOICE_TYPE.PROFORMA);

  let declaredShipmentValue = international?.declaredValue?.amount || 0;
  declaredShipmentValue = Number(declaredShipmentValue);

  let piece: Piece = shipment.pieces[0];

  let isDocumentsShipment = piece.document;

  let currency = isDocumentsShipment ? piece?.documentDeclaredValue?.currency : international?.declaredValue?.currency;
  if (!currency) currency = UNIT.CURRENCY_USD;

  const measurementSystem: string = unitHelper.defineMeasurementSystem(isDocumentsShipment, piece, piece?.weight?.unit);

  let isDocumentWeightAboveHalfPound = false;
  let documentWeight = 0;

  if (isDocumentsShipment && piece?.weight?.value > COMMON.EXPRESS_DOCUMENTS_MAX_WEIGHT) {
    isDocumentWeightAboveHalfPound = true;
    documentWeight = piece.weight.value;
  }

  let documents: Documents = {
    isDocumentWeightAboveHalfPound,
    documentWeight,
    isItJustDocument: true,
    numberOfPackages: piece?.quantity || 1,
    typeOfPackaging: piece?.packagingType || '',
    declaredShipmentValue: piece?.documentDeclaredValue?.amount || 0,
    generalDescription: isDocumentsShipment ? shipment?.description : '',
    isShipmentContainsDangerousGoods: false
  };

  let products: Products = {
    packages: mapPackages(shipment.pieces),
    isAnyBatteries: shipment.hasBatteries,
    declaredShipmentValue,
    generalDescription: shipment?.description || '',
    isShipmentContainsDangerousGoods: shipment.hasDangerousGoods,
    electronicExportFiling,
    foreignTradeRegulations,
    isItnNumberExists,
    ITNNumber: ITNNumber || '',
    EINNumber: EINNumber || '',
    invoiceType: isProformaInvoiceType ? INVOICE_TYPE.PROFORMA : invoiceType,
    purposeOfShipment: isProformaInvoiceType ? invoiceType : '',
    exportType: international?.exportType,
    isShipmentReferenceNumberExists: shipment?.externalSourceReference ? true : false,
    shipmentReferenceNumber: shipment?.externalSourceReference || '',
    isInvoiceNumberExists: international?.invoiceNumber ? true : false,
    invoiceNumber: international?.invoiceNumber || '',
    isReceiverReferenceExists: shipment?.receiverReference ? true : false,
    receiverReference: shipment?.receiverReference || '',
    isAnyPackageMarks: international?.packageMarks ? true : false,
    packageMarks: international?.packageMarks || '',
    isAnyOtherRemarks: isEmpty(international?.packageRemarks) ? false : true,
    otherRemarks: isEmpty(international?.packageRemarks) ? '' : international?.packageRemarks[0],
    thirdPartyPayorInfo: international?.thirdPartyPayer ? true : false,
    items: mapInvoiceItems(international?.exportLineItems, destination?.countryCode),
    freightCost: international?.freightCharge?.amount || 0,
    insuranceCost: international?.insuranceCharge?.amount || 0,
    miscCost: international?.optionalMiscCharge?.amount || 0
  };

  let shipmentDetails = {
    documents,
    products
  };

  let doYouNeedInsurance = false;
  if (shipment?.insuredValue?.amount && shipment?.insuredValue?.amount > 0) {
    doYouNeedInsurance = true;
  }

  let additionalServices: AdditionalServices = {
    doYouNeedInsurance,
    insuranceValue: shipment?.insuredValue?.amount || 0,
    isNeutralDeliveryServiceRequired: shipment.neutralDeliveryServiceAdded,
    isCertificateOfOriginRequired: shipment.certificateOfOriginAdded,
    signature: shipment.signature, // '', //Fix-Mapping:
    isReturnLabel: undefined //Fix-Mapping:
  };

  let payment: Payment = {
    freightPayment: shipment?.shippingPaymentType || '',
    shipperAccountNumber: shipment?.billingAccountNumber || '',
    dutiesAndTaxesPayment: international?.dutyPaymentType || '',
    dutyAccountNumber: international?.dutyAccountNumber || '',
    customsTermsOfTrade: international?.termsOfTrade?.code || '',
    billingAccountNumberPostalCode: shipment?.billingAccountNumberPostalCode || '',
    termsAndConditionsAgreement: true
  };

  let dataObject: ShipmentDataObject = {
    id: shipment.id,
    shipmentFrom,
    shipmentTo,
    shipmentDetails,
    additionalServices,
    payment,
    isDocumentsShipment,
    currency,
    measurementSystem
  };

  let instance = entities.shipmentData.createFromData(dataObject);

  return instance;
}

//helper method

function mapAddress(address: ShipmentOriginDestination) {
  let result: Address = {
    addressLine1: address?.addressLine1 || '',
    addressLine2: address?.addressLine2 || '',
    addressLine3: address?.addressLine3 || '',
    city: address?.city || '',
    postalCode: address?.postalCode || '',
    division: address?.administrativeArea || address?.state || '',
    suburb: address?.dependentLocality || ''
  };

  return result;
}

function mapPackages(pieces) {
  let packages: any = [];

  pieces.forEach((piece: Piece) => {
    let item = entities.draft.createPackage({
      weight: piece.actualWeight?.value ? piece.actualWeight.value : piece.weight.value,
      height: piece.depth.value,
      width: piece.width.value,
      length: piece.length.value,
      numberOfPackages: piece.quantity,
      pieceId: piece.trackingNumber,
      declaredValue: piece.documentDeclaredValue?.amount
    });

    packages.push(item);
  });

  return packages;
}

function mapInvoiceItems(exportLineItems: Array<ExportLineItem>, countryCode) {
  if (isEmpty(exportLineItems)) return [entities.draft.getDefaultInvoiceItem()];

  return exportLineItems.map(exportLineItem => {
    let licenseExpiryDate: any = exportLineItem?.license?.expiry;
    if (licenseExpiryDate) {
      licenseExpiryDate = new Date(licenseExpiryDate);
    }

    let itemValue = exportLineItem?.value?.amount ? Number(exportLineItem.value.amount) : 0;
    let quantity = exportLineItem?.quantity?.amount ? Number(exportLineItem.quantity.amount) : 0;

    let productItem = entities.draft.createInvoiceItem(
      {
        commodityCode: exportLineItem.commodityCode,
        itemDescription: exportLineItem.description,
        quantity,
        units: exportLineItem?.quantity?.unit,
        itemValue,
        itemMade: exportLineItem?.countryOfOrigin?.code,
        eccnValue: exportLineItem.eccn,
        exportLicenseNumber: exportLineItem?.license?.number,
        exportLicenseExpiryDate: licenseExpiryDate,
        isLicenseSymbolRequired: exportLineItem?.license?.symbol ? true : false,
        licenseSymbol: exportLineItem?.license?.symbol,
        weight: exportLineItem?.weight?.value ?? 0
      },
      countryCode
    );

    return productItem;
  });
}
