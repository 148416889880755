import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {get} from 'lodash';

import draftActions from 'actions/draftActions';
import {AppState} from 'reducers';

import SECTION from 'constants/sections';

import Container from 'components/common/Container';
import FormHeader from 'components/common/FormHeader';
import TextInput from 'components/common/TextInput';
import ReferenceNumberTooltip from 'components/tooltips/ReferenceNumberTooltip';
import Packages from '../packages';
import DangerousGoodsToggler from 'components/common/DangerousGoodsToggler';
import Flex from '../../../common/Flex';
import CheckBoxInput from '../../../common/CheckboxInput';
import QuestionMark from '../../../common/Marks/QuestionMark';
import ExclusivelyDocumentsTooltip from '../../../tooltips/ExclusivelyDocumentsTooltip';
import styled from 'styled-components/macro';
import {mediaQueries} from '../../../../styles/shared';
import FEATURE from '../../../../constants/features';

const StyledSeparator = styled.div`
  width: 4.5rem;
  margin-top: 1rem;

  @media ${mediaQueries.mobileMax} {
    display: none;
  }
`;

const StyledCheckboxes = styled(Flex)`
  align-items: flex-start;
  margin-top: -0.4rem;

  @media ${mediaQueries.desktopMax} {
    margin-top: 0;
  }

  @media ${mediaQueries.mobileMax} {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 3.7rem 1rem;
  }
`;

function DomesticShipmentDetails() {
  const dispatch = useDispatch();

  const draft = useSelector((state: AppState) => state.draft.current.data as Draft);
  const rate = useSelector((state: AppState) => state.draft.rate.current);
  const errors = useSelector((state: AppState) => state.draft.current.errors);

  function onChange(field, value) {
    dispatch(draftActions.modifyDraft('shipmentDetails', field, value));
  }

  function onProductsChange(field, value) {
    onChange('products', {...draft.shipmentDetails.products, [field]: value});
  }

  function onReferenceNumberChange(field, value) {
    onChange('products', {...draft.shipmentDetails.products, isShipmentReferenceNumberExists: true, [field]: value});
  }

  function onToggle(field, value) {
    // Empty toggle function for right now.
  }

  function render() {
    const products = draft.shipmentDetails.products;

    return (
      <Container id={SECTION.DRAFT.DOMESTIC_DETAILS}>
        <FormHeader>Shipment Details</FormHeader>

        { FEATURE.UPS_PREP_DOMESTIC_DOCUMENT_SELECTION && (
          <>
            <StyledSeparator />
            <StyledCheckboxes align="center">

              <Flex align="center">
                <CheckBoxInput
                  name="isDocumentsShipment"
                  label="Shipment contains exclusively Documents"
                  value={draft.isDocumentsShipment}
                  onToggle={onToggle}
                />

                <QuestionMark
                  id="exclusively-documents-tooltip"
                  tooltip={ExclusivelyDocumentsTooltip()}
                  placement="top"
                />
              </Flex>

              <StyledSeparator />
            </StyledCheckboxes>
          </>
        )}

        <Packages
          packages={products.packages}
          carrier={rate?.carrier}
          currency={draft.currency}
          isDomestic={draft.isDomestic}
          measurementSystem={draft.measurementSystem}
          errorPath="shipmentDetails.products.packages"
          errors={errors}
          onChange={onProductsChange}
        />

        <TextInput
          name="shipmentReferenceNumber"
          label="Reference Number"
          placeholder="Type in reference number"
          value={products.shipmentReferenceNumber}
          tooltip={ReferenceNumberTooltip()}
          onChange={onReferenceNumberChange}
          error={get(errors, 'shipmentDetails.products.shipmentReferenceNumber', '')}
          isWarning={products.isShipmentReferenceNumberExists && !products.shipmentReferenceNumber}
        />

        <DangerousGoodsToggler
          value={products.isShipmentContainsDangerousGoods}
          errors={errors}
          onChange={onProductsChange}
        />
      </Container>
    );
  }

  return render();
}

export default DomesticShipmentDetails;
