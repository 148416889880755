import {isInteger, replace} from 'lodash';

import COMMON from 'constants/common';
import SLIDER from 'constants/slider';
import MEASUREMENT_SYSTEM from 'constants/measurementSystems';
import UNIT from 'constants/units';
import CARRIER from 'constants/carriers';

import dateHelper from 'helpers/dateHelper';
import unitHelper from './unitHelper';
import config from 'config';
import FEATURE from '../constants/features';

export default {
  displayValue,
  displayBooleanValue,
  displayPieceItem,
  displayDocumentItem,
  displayPrice,
  displayDollarAmount,
  displayPastDate,
  displayTimeInAmPmFormat,
  convertTimeStringIntoNumber,
  truncateTextToLength,
  displayUserEmail,
  removeSpacesFromString,
  replaceNonDigitsInString,
  getIdFromIri,
  getDefaultMinSliderTime,
  getMinimumInterval,
  displayLineItemWeight,
  formatCommodityCode,
  calculateAccuratePrecision,
  getAccurateNumberString
};

function displayValue(value) {
  if (value) return value;

  return COMMON.EMPTY_FIELD;
}

function displayBooleanValue(value: boolean | undefined) {
  return value ? 'Yes' : 'No';
}

function displayPieceItem(piece: Package, measurementSystem: string, withConversion: boolean, isImport: boolean) {
  let displayValue = val => {
    const num = Number(val);

    if (isInteger(num)) return val;

    return num.toFixed(2);
  };

  let weight: number = piece.weight;
  let weightUnit: string = unitHelper.getUnitFromMeasurementSystem(measurementSystem, true);

  let length: number = piece.length;
  let width: number = piece.width;
  let height: number = piece.height;
  let dimensionUnit: string = unitHelper.getUnitFromMeasurementSystem(measurementSystem);

  if (withConversion) {
    if (isImport && measurementSystem === MEASUREMENT_SYSTEM.IMPERIAL) {
      weight = unitHelper.convertWeightBetweenUnits(weight, weightUnit);
      length = unitHelper.convertDimensionBetweenUnits(length, dimensionUnit);
      width = unitHelper.convertDimensionBetweenUnits(width, dimensionUnit);
      height = unitHelper.convertDimensionBetweenUnits(height, dimensionUnit);
      weightUnit = UNIT.MASS_KG;
      dimensionUnit = UNIT.LENGTH_CM;
    }

    if (!isImport && measurementSystem === MEASUREMENT_SYSTEM.METRIC) {
      weight = unitHelper.convertWeightBetweenUnits(weight, weightUnit);
      length = unitHelper.convertDimensionBetweenUnits(length, dimensionUnit);
      width = unitHelper.convertDimensionBetweenUnits(width, dimensionUnit);
      height = unitHelper.convertDimensionBetweenUnits(height, dimensionUnit);
      weightUnit = UNIT.MASS_POUND;
      dimensionUnit = UNIT.LENGTH_INCH;
    }
  }

  const displayWeight = `${displayValue(weight)}${weightUnit}`;
  const dimensions = `${displayValue(length)}*${displayValue(width)}*${displayValue(height)}${dimensionUnit}`;

  if (FEATURE.SHIPMENT_OVERVIEW_SHOW_PIECE_ID_DISPLAY && piece.pieceId) {
    return `${piece.numberOfPackages} X ${displayWeight} ${dimensions} 
      (Piece ID: ${piece.pieceId})`;
  } else {
    return `${piece.numberOfPackages} X ${displayWeight} ${dimensions}`;
  }
}

function displayDocumentItem(documents: Documents, measurementSystem: string, isImport) {
  let weight: number = documents.documentWeight;
  let weightUnit: string = unitHelper.getUnitFromMeasurementSystem(measurementSystem, true);

  if (isImport && measurementSystem === MEASUREMENT_SYSTEM.IMPERIAL) {
    weight = unitHelper.convertWeightBetweenUnits(weight, weightUnit);
    weightUnit = UNIT.MASS_KG;
  }

  if (!isImport && measurementSystem === MEASUREMENT_SYSTEM.METRIC) {
    weight = unitHelper.convertWeightBetweenUnits(weight, weightUnit);
    weightUnit = UNIT.MASS_POUND;
  }

  const weightDisplay = `${weight} ${weightUnit}`;

  return `${documents.numberOfPackages} X ${weightDisplay} X ${documents.typeOfPackaging}`;
}

function displayPrice(value, currency) {
  if (!value) return COMMON.EMPTY_FIELD;

  let amount = Number(value).toFixed(2);

  return `${amount} ${currency}`;
}

function displayDollarAmount(rate) {
  return rate ? `$${Number(rate).toFixed(2)}` : `$${COMMON.EMPTY_RATE}`;
}

function displayPastDate(date) {
  if (dateHelper.yesterdayDate(date)) return 'Yesterday';

  return dateHelper.displayDate(date, config.format.monthDay);
}

function displayTimeInAmPmFormat(time: number, withLeadZero = false) {
  let hours = Math.floor(time / 60);
  let minutes = time % 60;

  let addLeadZero = num => {
    return num < 10 ? `0${num}` : num;
  };

  let hoursDisplay = 0;
  let minutesDisplay = addLeadZero(minutes);
  let period = '';

  if (time >= SLIDER.AFTERNOON_TIME && time < SLIDER.MIDNIGHT_TIME) {
    hoursDisplay = hours % 12;
    if (!hoursDisplay) hoursDisplay = 12;
    period = 'PM';
  } else {
    hoursDisplay = !hours ? 12 : hours;
    if (hours > 12) hoursDisplay = 12;
    period = 'AM';
  }

  if (withLeadZero) {
    hoursDisplay = addLeadZero(hoursDisplay);
    period = ` ${period}`;
  }

  return `${hoursDisplay}:${minutesDisplay}${period}`;
}

function convertTimeStringIntoNumber(dateStr: string | undefined, defaultValue) {
  if (!dateStr || dateStr === COMMON.EMPTY_FIELD) return defaultValue;

  // Check for DHL empty values
  if (dateStr === '00:00:00' || dateStr === '12:00 AM') return defaultValue;

  let [hours, minutes] = dateStr.split(':');

  let numberHours = Number(hours);

  if (minutes.toLowerCase().includes('pm') && numberHours !== 12) {
    numberHours += 12;
  } else if (minutes.toLowerCase().includes('am') && numberHours === 12) {
    numberHours = 0;
  }

  minutes = minutes.split(' ')[0];

  let pickupTime = numberHours * 60 + Number(minutes);

  return pickupTime;
}

function truncateTextToLength(text = '', length, truncateFrom = 'start') {
  if (!text || !length || text.length <= length) return text;

  switch (truncateFrom) {
    case 'middle':
      let segmentSize = Math.ceil((length - 3) / 2);
      return text.substring(0, segmentSize) + '...' + text.substring(text.length - segmentSize, text.length);
    case 'start':
      return text.substring(0, length) + '...';
    case 'end':
      return '...' + text.substring(text.length - length, text.length);
    default:
      return text;
  }
}

function displayUserEmail(email: string | undefined) {
  if (!email) return '';

  const start = email.substring(0, 2);
  const end = email.slice(email.lastIndexOf('.') + 1);

  return `${start}*****@*****.${end}`;
}

function removeSpacesFromString(str: string) {
  return str.split(' ').join('');
}

function getIdFromIri(iri: string) {
  let arrayOfStrings = iri.split('/');
  return arrayOfStrings[arrayOfStrings.length - 1];
}

function getDefaultMinSliderTime(carrier?: string): number {
  return carrier === CARRIER.DHL ? SLIDER.DHL_MIN_TIME : SLIDER.DOMESTIC_MIN_TIME;
}

function getMinimumInterval(carrier?: string, minimumPickupWindow?: string): number {
  // PT6H0M
  if (minimumPickupWindow && carrier === CARRIER.FED_EX) {
    const time = minimumPickupWindow.toUpperCase().slice(2).split('H');
    const minutes = Number(time[0]) * 60 + Number(time[1].split('M')[0]);
    return minutes;
  }

  return (!carrier || carrier === CARRIER.DHL) ? SLIDER.DHL_MIN_INTERVAL : SLIDER.UPS_MIN_INTERVAL;
}

function displayLineItemWeight(weight: number | undefined, massUnit: string): string {
  return `${getAccurateNumberString(weight ?? 0, 3)} ${massUnit}`;
}

function calculateAccuratePrecision(value: number, decimalPlaces: number): number {
  return Number(Math.round(parseFloat(value + 'e' + decimalPlaces)) + 'e-' + decimalPlaces);
}

function getAccurateNumberString(value: number, decimalPlaces: number): string {
  return calculateAccuratePrecision(value, decimalPlaces).toFixed(decimalPlaces);
}

function replaceNonDigitsInString(value: string) {
  return replace(value, /\D/g, '');
}

function formatCommodityCode(value: string) {
  let code = replaceNonDigitsInString(value);
  let insertDotAt = index => code.slice(0, index) + '.' + code.slice(index);
  if (code.length > 4) code = insertDotAt(4);
  if (code.length > 6 + 1) code = insertDotAt(4 + 2 + 1);
  return code;
}
