import React, {useState, useEffect} from 'react';
import {useParams, useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import styled from 'styled-components/macro';

import {AppState} from 'reducers';
import commonActions from 'actions/commonActions';
import shipmentActions from 'actions/shipmentActions';
import pickupActions from 'actions/pickupActions';

import FEATURE from 'constants/features';
import MESSAGE from 'constants/literals/messages';
import SEARCH_PARAMETER from 'constants/searchParameters';

import navigationHelper from 'helpers/navigationHelper';

import pickupService from 'services/pickupService';
import heapAnalyticsService from 'services/heapAnalyticsService';

import CloseButton from 'components/common/CloseButton';
import Flex from 'components/common/Flex';
import Button from 'components/common/Button';
import FormHeader from 'components/common/FormHeader';
import PickupsSummary from './components/pickups/PickupsSummary';
import ShipmentOverview from './components/overview/ShipmentOverview';
import SurveyLink from './components/survery/SurveyLink';

import {colors, secondaryFont} from 'styles/shared';
import ShipmentTotal from 'components/prepare_shipment/components/total/ShipmentTotal';
import quoteActions from 'actions/quoteActions';
import UpdatedDocumentsSummary from './components/documents/UpdatedDocumentsSummary';
import DocumentsSummary from './components/documents/DocumentsSummary';

const StyledPageContainer = styled.div`
  height: 100%;
  width: 54.6rem;
`;

const StyledHeader = styled(FormHeader)`
  color: ${colors.orange_main};
`;

const StyledClosePanel = styled(Flex)`
  margin-bottom: 3rem;
`;

const StyledSubTitle = styled.div`
  margin-top: 1rem;
  margin-bottom: 3rem;
  ${secondaryFont};
`;

const StyledCloseButtonContainer = styled(Flex)`
  margin-top: 5rem;
  margin-bottom: 10rem;
`;

interface ShipmentSummaryPageParams {
  id: string;
}

function ShipmentSummaryPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const {search} = useLocation();

  const {id: shipmentId} = useParams<ShipmentSummaryPageParams>();

  const pickups = useSelector((state: AppState) => state.pickup.list);
  const pagination = useSelector((state: AppState) => state.pickup.pagination);

  const [shipment, setShipment] = useState({} as Shipment);
  const [pickup, setPickup] = useState<Optional<Pickup>>();
  const [activeQuote, setActiveQuote] = useState<Quote>();
  const [isNewlyCreated, setIsNewlyCreated] = useState(false);
  const [surveyLinkVisible, setSurveyLinkVisible] = useState(true);

  useEffect(() => {
    initData();
  }, [shipmentId]);

  useEffect(() => {
    let pickupItem = pickupService.findRelatedPickup(pickups, shipmentId);
    if (pickupItem) setPickup(pickupItem);
  }, [pickups]);

  async function initData() {
    if (!shipmentId) return;

    const query = new URLSearchParams(search);
    const newShipment = query.get(SEARCH_PARAMETER.LOCAL.NEW_SHIPMENT_OVERVIEW);
    const isNewShipment = newShipment === null ? false : true;
    setIsNewlyCreated(isNewShipment);

    if (isNewShipment) {
      heapAnalyticsService.track('Shipment Prep Completed');
    }

    await dispatch(pickupActions.loadPickups(pagination.activePage, pagination.pageSize));
    const activeQuote: any = await dispatch(quoteActions.getActiveQuoteForShipment(shipmentId));
    setActiveQuote(activeQuote as Quote);

    let initialShipment: any = await dispatch(shipmentActions.loadShipment(shipmentId));

    if (initialShipment) setShipment(initialShipment);
  }

  function cancelPickup() {
    dispatch(
      commonActions.confirmAction({
        title: MESSAGE.CANCEL_PICKUP_TITLE,
        text: MESSAGE.CANCEL_PICKUP_TEXT,
        action: async () => {
          await dispatch(pickupActions.cancelPickupAction(pickup?.id));
        }
      })
    );
  }

  async function editPickupAction(pickupToEdit: PickupData, pickupId) {
    await dispatch(pickupActions.editPickup(pickupToEdit, pickupId));
  }

  async function addPickupAction(pickupData: PickupData) {
    let data: ConfirmPickupForShipmentDto = {
      ...pickupData,
      shipmentId
    };

    await dispatch(pickupActions.schedulePickupForShipment(data));
  }

  function closeSummaryPage() {
    history.push(navigationHelper.getViewHistoryPageLink());
  }

  function render() {
    if (!shipment || isEmpty(shipment)) return null;

    const header = isNewlyCreated ? 'Congratulations!' : 'Shipment Overview';

    const showSurveyLink = isNewlyCreated && surveyLinkVisible;

    return (
      <StyledPageContainer>
        {!isNewlyCreated && (
          <StyledClosePanel justify="flex-end">
            <CloseButton onClick={closeSummaryPage} />
          </StyledClosePanel>
        )}

        <StyledHeader>{header}</StyledHeader>

        <StyledSubTitle>
          You have successfully created a new <strong>shipment!</strong>
          <br />
          These are few steps that you can take now:
        </StyledSubTitle>

        {showSurveyLink && <SurveyLink hideSurveyLink={() => setSurveyLinkVisible(false)} />}

        { FEATURE.UPDATED_DOCUMENT_ERROR_HANDLING
          ? <UpdatedDocumentsSummary documents={shipment.documents} shipmentId={shipmentId} />
          : <DocumentsSummary shipmentId={shipmentId} documents={shipment.documents} />
        }


        <PickupsSummary
          shipment={shipment}
          pickup={pickup}
          date={shipment.targetShipDate}
          service={shipment?.carrier?.name}
          carrier={shipment?.carrier?.carrier}
          isImport={shipment?.isImport}
          cancelPickupAction={cancelPickup}
          editPickupAction={editPickupAction}
          addToPickupAction={addPickupAction}
        />

        <ShipmentOverview shipment={shipment} pickup={pickup} />

        <ShipmentTotal
          rateIsUpdating={false}
          rate={
            activeQuote ? activeQuote.rates.find(r => r.carrierProduct === shipment.carrier.productCode) : undefined
          }
          domestic={shipment.isDomestic}
          usTerritories={shipment.isUSTerritories}
          shipmentId={shipment.id}
          inspectionId={activeQuote?.createdInId}
          sticky={false}
          pageId={'shipment-summary'}
        />

        {isNewlyCreated && (
          <StyledCloseButtonContainer justify="center">
            <Button autoWidth onClick={closeSummaryPage}>
              Go To View History
            </Button>
          </StyledCloseButtonContainer>
        )}
      </StyledPageContainer>
    );
  }

  return render();
}

export default ShipmentSummaryPage;
