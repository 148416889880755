import React, {useState, useEffect, useRef} from 'react';
import {Row, Col} from 'components/bootstrap';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components/macro';
import {useHistory, useLocation} from 'react-router-dom';
import $ from 'jquery';
import {debounce} from 'lodash';

import {AppState} from 'reducers';
import commonActions from 'actions/commonActions';
import prepAndPickupActions from 'actions/prepAndPickupActions';
import pickupActions from 'actions/pickupActions';
import draftActions from 'actions/draftActions';
import userActions from 'actions/userActions';
import paymentActions from 'actions/paymentActions';

import DRAFT_STEP from 'constants/draftSteps';
import MESSAGE from 'constants/literals/messages';
import COMMON from 'constants/common';
import SEARCH_PARAMETER from 'constants/searchParameters';
import TIMER from 'constants/timers';
import FEATURE from 'constants/features';
import PATH from 'constants/path';

import validationHelper from 'helpers/validationHelper';
import uiHelper from 'helpers/uiHelper';
import navigationHelper from 'helpers/navigationHelper';
import formatHelper from 'helpers/formatHelper';

import stateStorageService from 'services/stateStorageService';
import authService from 'services/authService';
import supportService from 'services/supportService';
import heapAnalyticsService from 'services/heapAnalyticsService';

import SendingSection from './components/sending/SendingSection';
import ShipmentDetails from './components/shipment_details/ShipmentDetails';
import ShipmentTotal from './components/total/ShipmentTotal';
import ButtonsRow from './components/ButtonsRow';
import AdditionalServices from './components/additional_services/AdditionalServices';
import PickupDelivery from './components/pickup_delivery/PickupDelivery';
import Payment from './components/payment/Payment';
import ReviewFinish from './components/review_finish/ReviewFinish';
import SaveDraftConfirmation from './components/SaveDraftConfirmation';
import CountriesSelect from './components/CountriesSelect';
import LeftPanel from './components/left_panel/LeftPanel';
import HouseAccountMessage from './components/HouseAccountMessage';
import ImportExportWarningModal from './components/ImportExportWarningModal';
import HouseAccountWarningModal from './components/HouseAccountWarningModal';

//domestic
import DangerousGoods from './components/domestic/DangerousGoods';
import DomesticShipmentDetails from './components/domestic/DomesticShipmentDetails';
import DomesticAdditionalServices from './components/domestic/DomesticAdditionalServices';

import extras from './extras';

import {maxContainerWidth} from 'styles/shared';

const StyledPageContainer = styled.div`
  width: ${maxContainerWidth};
`;

const SCROLL_EXTRA_PADDING = 250;

function PrepareShipmentPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const {pathname} = useLocation();

  const rate = useSelector((state: AppState) => state.draft.rate.current);
  const ratesError = useSelector((state: AppState) => state.draft.rate.error);
  const user = useSelector((state: AppState) => state.user.current as User);
  const isHouseAccountUsed = useSelector((state: AppState) => state.common.isHouseAccountUsed);
  const paymentId = useSelector((state: AppState) => state.payment.paymentId);
  const paymentTime = useSelector((state: AppState) => state.payment.paymentTime);
  const paymentNumber = useSelector((state: AppState) => state.draft.rate.current?.displayRetailRate) || 0;
  const paymentAmount = formatHelper.getAccurateNumberString(paymentNumber, 2);

  const eeiFilingTypeOptions = useSelector((state: AppState) => state.shipmentOptions.eeiFilingTypeOptions);

  const saveDraftModalVisible = useSelector((state: AppState) => state.common.saveDraftModalVisible);
  const pickupData = useSelector((state: AppState) => state.draft.pickup.dataToSave as PickupData);
  // const draftPickups = useSelector((state: AppState) => state.draft.pickup.list);
  const draft = useSelector((state: AppState) => state.draft.current.data as Draft);
  const currentStep = useSelector((state: AppState) => state.draft.current.step);
  const originAddressError = useSelector((state: AppState) => state.draft.current.originAddressError);
  const destinationAddressError = useSelector((state: AppState) => state.draft.current.destinationAddressError);
  const isDataUploaded = useSelector((state: AppState) => state.draft.current.isDataUploaded);
  const isExistingDraft = useSelector((state: AppState) => state.draft.current.isExistingDraft);
  const rateParamsChanged = useSelector((state: AppState) => state.draft.current.rateParamsChanged);
  const carrierProductChanged = useSelector((state: AppState) => state.draft.current.carrierProductChanged);

  const [activeSection, setActiveSection] = useState('');
  const [rateIsUpdating, setRateIsUpdating] = useState(false);
  const [dangerousSectionVisible, setDangerousSectionVisible] = useState(true);
  const [errorsScroll, setErrorsScroll] = useState(false);

  //house account variabled
  const [showUserExportImportWarning, setShowUserExportImportWarning] = useState(true);
  const [showCsrHouseAccountWarning, setShowCsrHouseAccountWarning] = useState(true);

  const rateShipmentRef = useRef(debounce(rateAction, TIMER.RATE_ACTION_DELAY));

  useEffect(() => {
    let authContext: AuthContext = authService.getAuthContext();

    if (authContext?.isCsrPersonalAccount) {
      dispatch(
        commonActions.infoAction({
          text: (
            <span>
              You can't create a shipment for yourself, as the CSR. Please click <strong>Users</strong> and search
              for the client you want to create the shipment for. Then click <strong>Sign into Profile</strong> to be
              able to create a shipment for them.
            </span>
          ),
          type: 'warning',
          close: () => {
            history.push(PATH.USERS);
          }
        })
      );
      return;
    }

    initData();

    return () => {
      //clear state on page leave
      dispatch(draftActions.clearDraftState());
      dispatch(paymentActions.clearPaymentState());

      dispatch(commonActions.toggleHouseAccountUsage(false));
    };
  }, []);

  useEffect(() => {
    //scroll top on current step change
    window.scroll({top: 0, left: 0});

    // Prep flow steps should change the URL for analytics
    history.replace(`${pathname}?step=${currentStep}`);
  }, [currentStep]);

  useEffect(() => {
    if (!activeSection) return;

    let element = document.getElementById(activeSection);

    if (element) element.scrollIntoView();

    setActiveSection('');
  }, [activeSection]);

  //calculate total rate
  useEffect(() => {
    if (!draft || !rateShipmentRef) return;

    const validationResult: ValidationResult = validationHelper.validateRateRequest(draft, rate?.carrier);

    if (!validationResult.valid) return;

    rateShipmentRef.current(draft);
  }, [rateParamsChanged]);

  // update displayed rate if carrier product changes
  useEffect(() => {
    if (!draft || !rate) return;

    dispatch(draftActions.updateDisplayedRate());
  }, [carrierProductChanged]);

  //scroll to the first issue that is not locally validated
  useEffect(() => {
    if (!errorsScroll) return;

    setErrorsScroll(false);

    let scrollTop = $('.validation-error:first')?.offset()?.top;

    if (scrollTop) {
      scrollTop = scrollTop - SCROLL_EXTRA_PADDING;
      $('html, body').animate({scrollTop}, 1000);
    }
  }, [errorsScroll]);

  //populate suggested DeclaredValue automatically
  useEffect(() => {
    if (!draft?.invoiceTotalSum) return;

    if (draft.isDocumentsShipment) return;

    dispatch(
      draftActions.modifyDraft('shipmentDetails', 'products', {
        ...draft.shipmentDetails.products,
        declaredShipmentValue: draft.invoiceTotalSum
      })
    );
  }, [draft?.invoiceTotalSum]);

  // Switch from document to products shipment if domestic shipment
  useEffect(() => {
    if (draft?.isDomestic && draft?.isDocumentsShipment) {
      dispatch(draftActions.modifyDraft(null,'isDocumentsShipment', false));
    }
  }, [draft?.isDomestic]);

  // This doesn't seem to do anything
  // When draft is mapped either both pickup and id are included or neither is included
  // I don't see what purpose it would serve in a saved draft either

  // useEffect(() => {
  //   let pickupDelivery = draft?.pickupDelivery;

  //   if (!pickupDelivery) return;

  //   if (pickupDelivery.pickupId) {
  //     let pickup: Pickup | undefined = draftPickups.find((item: Pickup) => item.id === pickupDelivery?.pickupId);

  //     if (pickup) {
  //       pickupDelivery.pickup = pickup;
  //     }
  //   }
  // }, [draftPickups]);

  async function initData() {
    let isUsed = authService.isHouseAccountUsed();
    dispatch(commonActions.toggleHouseAccountUsage(isUsed));

    await dispatch(userActions.loadBillingInformation());
    //do not load payment methods for guest user
    if (FEATURE.PAYMENT_AUTH && !user.isGuest) await dispatch(paymentActions.loadPaymentMethods());

    if (!isDataUploaded) {
      let unsavedDraft = stateStorageService.getUnsavedDraft();

      if (!unsavedDraft) {
        dispatch(draftActions.createNewDraft());
        return;
      }

      dispatch(
        commonActions.confirmAction({
          title: 'You have previously entered data. Would you like to use it?',
          action: async () => {
            await dispatch(draftActions.uploadDraftFromLocalStorage(unsavedDraft));
          },
          close: () => {
            stateStorageService.clearUnsavedDraft();
            dispatch(draftActions.createNewDraft());
          }
        })
      );
    }
  }

  async function rateAction(draft) {
    setRateIsUpdating(true);
    const data: RateShipmentDto = {draft, shipmentId: draft?.id};
    await dispatch(draftActions.rateShipment(data));
    setRateIsUpdating(false);
  }

  async function saveShipment() {
    if (currentStep === DRAFT_STEP.REVIEW_FINISH) {
      await createShipmentAction();
      return;
    }

    uiHelper.logMessage(`Client data; step ${currentStep}: `);
    uiHelper.logDataObject(draft);

    const result: any = await dispatch(draftActions.validateDraft());
    const validationResult = result as ValidationResult;

    if (!validationResult.valid) {
      uiHelper.logMessage('Validation errors:');
      uiHelper.logDataObject(validationResult);
      showGeneralErrorMessage(validationResult.errors);
      return;
    }

    //if draft is valid, save draft (for users only)
    if (!user.isCSR) await saveDraft();

    dispatch(draftActions.goToNextValidStep());
  }

  function showGeneralErrorMessage(errors) {
    let validateShipment = () => {
      let notificationError = errors[COMMON.ERROR_NOTIFICATIONS];
      if (notificationError) {
        dispatch(commonActions.infoAction({type: 'warning', text: notificationError[0], close: () => null}));
      }
    };

    let errorString = '';

    for (const error in errors) {
      errorString += ` ${error}`;
    }

    dispatch(
      commonActions.infoAction({
        text: 'There is some missing or incorrect information that needs to be fixed.',
        type: 'issue',
        logToRollbar: true,
        rollbarContext: `Prepare Shipment validation errors - ${errorString}`,
        action: () => {
          setErrorsScroll(true);
          validateShipment();
        },
        close: () => {
          validateShipment();
        }
      })
    );
  }

  async function createShipmentAction() {
    const prepareShipmentDTO: PrepareShipmentDto = {
      id: draft.id,
      draft,
      eeiFilingTypeOptions,
      paymentId,
      paymentAmount,
      paymentTime
    };

    let confirmPickupDTO: ConfirmPickupDto | null = null;
    let addShipmentToPickupDTO: AddShipmentToPickupDto | null = null;

    if (draft.pickupDelivery.pickupId) {
      addShipmentToPickupDTO = {
        pickupId: draft.pickupDelivery.pickupId,
        shipmentId: draft.id
      };
    } else if (draft.pickupDelivery.pickup) {
      confirmPickupDTO = {
        draft: draft,
        carrier: rate?.carrier,
        shipmentId: draft.id,
        carrierProduct: rate?.carrierProduct
      };
    }

    const shipmentId: any = await dispatch(prepAndPickupActions.completePrepAndPickup(
      prepareShipmentDTO,
      confirmPickupDTO,
      addShipmentToPickupDTO
    ));

    heapAnalyticsService.track('Prepare Shipment Attempted');

    if (!shipmentId) return;

    stateStorageService.clearUnsavedDraft();

    uiHelper.showNotice(MESSAGE.CREATE_SHIPMENT);

    history.push(`/shipment-summary/${shipmentId}?${SEARCH_PARAMETER.LOCAL.NEW_SHIPMENT_OVERVIEW}=true`);
  }

  function goToPreviousStep() {
    if (currentStep === DRAFT_STEP.REVIEW_FINISH) {
      dispatch(
        commonActions.confirmAction({
          title: 'Cancel shipment',
          action: () => {
            dispatch(draftActions.cancelDraft());
          }
        })
      );
      return;
    }

    dispatch(draftActions.goToPreviousStep());
  }

  function goToSectionStep(step, section) {
    dispatch(draftActions.goToDraftSection(step));

    setActiveSection(section ? section : undefined);
  }

  async function saveDraft() {
    let draftToSave = {
      ...draft,
      currentStep
    };

    if (isExistingDraft) {
      await dispatch(draftActions.updateDraft(draftToSave));
    } else {
      await dispatch(draftActions.createDraft(draftToSave));
    }
  }

  async function saveDraftAndSendToSupport() {
    await saveDraft();

    const data = await supportService.getSupportRequestDto(draft.id, rate?.id, currentStep);

    const response: any = await dispatch(commonActions.sendToSupport(data, false));
    const isSuccess = response as boolean;

    if (!isSuccess) return;

    await dispatch(commonActions.toggleSaveDraftModal(false));

    history.push(navigationHelper.getViewHistoryPageLink());

    uiHelper.showNotice(MESSAGE.SAVE_AND_SEND_DRAFT);
  }

  async function validatePickupAction() {
    let data: ValidatePickupDto = {
      draft: draft,
      pickup: pickupData,
      carrier: rate?.carrier,
      shipmentId: draft.id,
      carrierProduct: rate?.carrierProduct
    };

    const result: any = await dispatch(draftActions.validatePickupDataToSave());
    const validationResult = result as ValidationResult;

    if (!validationResult.valid) {
      showGeneralErrorMessage(validationResult.errors);
      return;
    }

    try {
      dispatch(commonActions.toggleGlobalOverlay());

      await dispatch(pickupActions.validatePickup(data));
    } finally {
      dispatch(commonActions.toggleGlobalOverlay());
    }
  }

  function render() {
    if (!draft) return <></>;

    let section: any = null;

    let totalSection = (
      <Col lg={6}>
        <ShipmentTotal
          rateIsUpdating={rateIsUpdating}
          rate={rate}
          domestic={draft.isDomestic}
          usTerritories={draft.isUSTerritories}
          shipmentId={draft.id}
          ratesError={ratesError}
          currentStep={currentStep}
          inspectionId={rate?.createdInId}
          sticky={true}
          pageId={'prepare-shipment'}
        />
      </Col>
    );

    switch (currentStep) {
      case DRAFT_STEP.CREATE_SHIPMENT:
        section = (
          <>
            <CountriesSelect originCountry={draft.shipmentFrom.country} destinationCountry={draft.shipmentTo.country} />

            {draft.isDomestic && dangerousSectionVisible && (
              <DangerousGoods close={() => setDangerousSectionVisible(false)} />
            )}

            <SendingSection
              sending={draft.shipmentFrom}
              sendingDirection="from"
              isDomestic={draft.isDomestic}
              isShipper={true}
              addressError={originAddressError}
            />

            <SendingSection
              sending={draft.shipmentTo}
              sendingDirection="to"
              isDomestic={draft.isDomestic}
              isShipper={false}
              addressError={destinationAddressError}
            />

            <Row>
              <Col lg={6}>{draft.isDomestic ? <DomesticShipmentDetails /> : <ShipmentDetails />}</Col>

              {totalSection}
            </Row>
          </>
        );
        break;

      case DRAFT_STEP.ADDITIONAL_SERVICES:
        section = (
          <Row>
            <Col lg={6}>
              <PickupDelivery validatePickupAction={validatePickupAction} />

              {(draft.isDocumentsShipment && !FEATURE.RETURN_LABEL) ? <></> :
                draft.isDomestic ? <DomesticAdditionalServices /> : <AdditionalServices />}
            </Col>

            {totalSection}
          </Row>
        );
        break;

      case DRAFT_STEP.PAYMENT:
        section = (
          <Row>
            <Col lg={6}>
              <Payment />
            </Col>
            {totalSection}
          </Row>
        );
        break;

      case DRAFT_STEP.REVIEW_FINISH:
        section = (
          <Row>
            <Col lg={6}>
              <ReviewFinish navigateTo={goToSectionStep} />
            </Col>
            {totalSection}
          </Row>
        );
        break;

      default:
        break;
    }

    let isCSR = user.isCSR ? true : false;

    let userExportImportWarningVisible = extras.handleUserWithoutImportExportStatus(
      user,
      draft.isExport,
      draft.isImport
    );

    let csrHouseAccountWarningVisible = extras.csrHouseAccountWarningVisible(
      isCSR,
      isHouseAccountUsed,
      draft.isExport,
      draft.isImport
    );

    return (
      <>
        <LeftPanel isCSR={isCSR} />

        {isCSR && isHouseAccountUsed && <HouseAccountMessage />}

        <StyledPageContainer>
          {section}

          <ButtonsRow
            currentStep={currentStep}
            onCancel={goToPreviousStep}
            onSave={saveShipment}
            overrideSaveButton={userExportImportWarningVisible}
            saveDraftAndSendToSupport={saveDraftAndSendToSupport}
          />

          {saveDraftModalVisible && (
            <SaveDraftConfirmation
              visible={saveDraftModalVisible}
              saveDraft={saveDraft}
              saveDraftAndSendToSupport={saveDraftAndSendToSupport}
            />
          )}

          {userExportImportWarningVisible && showUserExportImportWarning && (
            <ImportExportWarningModal
              visible={showUserExportImportWarning}
              isExport={draft.isExport}
              close={() => setShowUserExportImportWarning(false)}
            />
          )}

          {csrHouseAccountWarningVisible && showCsrHouseAccountWarning && (
            <HouseAccountWarningModal
              visible={showCsrHouseAccountWarning}
              isExport={draft.isExport}
              close={() => setShowCsrHouseAccountWarning(false)}
            />
          )}
        </StyledPageContainer>
      </>
    );
  }

  return render();
}

export default PrepareShipmentPage;
