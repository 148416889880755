const FEATURE = {
  ORDERS: false,
  PAID_COLUMN_VISIBLE: false,
  UNUSED_HISTORY_COLUMNS_VISIBLE: false,
  RETURN_LABEL: false,
  COMMODITY_CODE_SEARCH: false,
  QUOTE_MODAL_SHIPMENT_DETAILS: false,
  PAYMENT_AUTH: false,
  PAYMENT_AUTOPAY: false,
  CLIENT_DISCOUNT_MESSAGE: false,
  CHANGE_USER_PASSWORD_BY_CSR: false,
  INSPECTION_URL_VISIBLE: false,
  GUEST_LANDING_PAGE_3_0: false,
  SHARE_QUOTE_VISIBLE: false,
  INSTANT_SIGN_UP: false,
  PICKUP_OPTIONS: false,
  SHIPMENTS_NEED_ATTENTION_VISIBLE: false,
  UPDATED_DOCUMENT_ERROR_HANDLING: true,
  SHIPMENT_OVERVIEW_SHOW_PIECE_ID_DISPLAY: true,
  DHL_PREP_DIRECT_SIGNATURE_OPTION: true,
  SHIPMENT_OVERVIEW_ESTIMATED_ARRIVAL_DATE: true,
  UPS_PREP_DOMESTIC_DOCUMENT_SELECTION: false,
};

export default FEATURE;
