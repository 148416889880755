import {minBy, orderBy} from 'lodash';

import SURCHARGE from 'constants/literals/surcharges';

import uiHelper from 'helpers/uiHelper';

type SURCHARGE_TYPE =
  | 'billing_duties'
  | 'nds'
  | 'sd'
  | 'insurance'
  | 'measurement_system'
  | 'package'
  | 'domestic_receivers_residential'
  | 'receivers_residential'
  | 'signature'
  | 'pickup';

export default {
  isIncompleteRate,
  calculateRateBeforeDiscount,
  calculateSavingsAmount,
  addFeesToCalculatedRate,
  getDomesticRatesLookup,
  getRateIdWithLowerPrice,
  displaySurcharge
};

function isIncompleteRate(rate: Rate): boolean {
  return rate.savingsPercent < 0 ? true : false;
}

function calculateRateBeforeDiscount(rate: Rate): number {
  if (rate.savingsPercent === 0) return rate.displayOptimalRate;
  return rate.displayOptimalRate / ((100 - rate.savingsPercent) / 100);
}

function calculateSavingsAmount(rate: Rate, beforeDiscount?: number): number {
  if (beforeDiscount !== undefined) {
    return beforeDiscount - rate.displayOptimalRate;
  }

  const valueBeforeDiscount = calculateRateBeforeDiscount(rate);
  return valueBeforeDiscount - rate.displayOptimalRate;
}

function addFeesToCalculatedRate(rate: Rate, calculatedRate: number): number {
  let calculatedTotal = calculatedRate;
  rate.fees.forEach((fee) => {
    calculatedTotal += fee.amount.amount;
  });

  return calculatedTotal;
}

function getDomesticRatesLookup(rates: Rate[], selectedCarriers: string[]): Rate[] {
  const filteredRates = rates.filter(rate => selectedCarriers.includes(rate.carrier));

  const result = orderBy(
    filteredRates,
    [
      function (rate) { return rate.displayRetailRate; },
      function (rate) { return new Date(rate.estimatedDelivery); }
    ],
    ['asc','desc']
  );

  return result;
}

function getRateIdWithLowerPrice(rates: Rate[]): string | undefined {
  //the lowest price of ANY carrier rate. (So this will not show if UPS has the lowest price but only FedEx is checked to show).
  const lowerPriceRate = minBy(rates, rate => rate.displayRetailRate);

  return lowerPriceRate?.id;
}

function displaySurcharge(type: SURCHARGE_TYPE, isAdded?: boolean) {
  let message = '';

  switch (type) {
    case 'billing_duties':
      message = isAdded ? SURCHARGE.BILLING_DUTIES_ADDED : SURCHARGE.BILLING_DUTIES_REMOVED;
      break;

    case 'nds':
      message = isAdded ? SURCHARGE.NDS_ADDED : SURCHARGE.NDS_REMOVED;
      break;

    case 'insurance':
    case 'package':
    case 'signature':
    case 'domestic_receivers_residential':
      message = SURCHARGE.PRICE_UPDATED;
      break;

    case 'pickup':
      message = SURCHARGE.PICKUP_REMOVED;
      break;

    case 'measurement_system':
      message = SURCHARGE.MEASUREMENT_SYSTEM_UPDATED;
      break;

    case 'receivers_residential':
      message = isAdded ? SURCHARGE.RECEIVERS_RESIDENTIAL_ADDED : SURCHARGE.RECEIVERS_RESIDENTIAL_REMOVED;
      break;

    default:
      break;
  }

  if (!message) return;

  uiHelper.showNotice(message, true);
}
