const MESSAGE = {
  //draft
  SAVE_DRAFT: 'You have successfully saved your Draft. You can find it in Draft section.',
  SAVE_AND_SEND_DRAFT:
    'Your draft was successfully sent to OptimalShip Customer Support. They will finish your Shipments as soon as possible!',
  REMOVE_DRAFT: 'You have successfully removed your Draft.',
  QUOTE_REPORT: 'You have successfully reported a Problem.',

  //pickup
  CONFIRM_PICKUP: 'You have successfully confirmed your Pickup.',
  UPDATE_PICKUP: 'You have successfully updated your Pickup.',
  REMOVE_SHIPMENTS_FROM_PICKUP: 'You have successfully removed Shipments from your Pickup.',
  ADD_SHIPMENT_TO_PICKUP: 'You have successfully added Shipment to previous Pickup.',
  CANCEL_PICKUP: 'You have successfully canceled your Pickup.',
  CANCEL_PICKUP_TITLE: 'Are you sure you want to cancel pickup?',
  CANCEL_PICKUP_TEXT: 'You can later edit shipment and create new pickup.',
  VALIDATE_PICKUP: 'Pickup is valid. You can continue with the shipment creation process.',
  VALIDATE_PICKUP_DOMESTIC: 'Pickup is valid. Pickup fee has been added to your rate. You can continue with the shipment creation process.',

  //shipment
  CREATE_SHIPMENT: 'You have successfully created your Shipment.',
  ESTIMATED_DELIVERY_DATE: 'Estimated Delivery Date',

  //address book
  CREATE_ADDRESS: 'You have successfully saved new contact in Address Book!',
  UPDATE_ADDRESS: 'You have successfully Updated contact in Address Book!',

  //common
  COPIED_TO_CLIPBOARD: 'Copied to clipboard',
  SEND_TO_SUPPORT: 'Your message was successfully sent to OptimalShip Customer Support.',

  //auth
  CHANGE_PASSWORD:
    'You have created new password and are now signed in! You may continue preparing your Shipment and use all of the features!',
  SET_PASSWORD: 'You have successfully set your password, and are now able to access all features.',

  //charges
  TOTAL_CHARGE:
    'Total Estimated Charges',
  SHIPPING_CHARGE:
    'Shipping Charge',
  SAVINGS:
    'Your Savings',
};

export default MESSAGE;
