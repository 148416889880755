import React, {useState} from 'react';
import styled from 'styled-components/macro';
import {useSelector, useDispatch} from 'react-redux';

import {AppState} from 'reducers';
import commonActions from 'actions/commonActions';

import MESSAGE from 'constants/literals/messages';

import uiHelper from 'helpers/uiHelper';
import navigationHelper from 'helpers/navigationHelper';

import supportService from 'services/supportService';
import rateService from 'services/rateService';

import Container from 'components/common/Container';
import Button from 'components/common/Button';
import TotalSum from 'components/common/TotalSum';

import ReportQuoteModal from './components/ReportQuoteModal';
import ProgressIndicator from './components/ProgressIndicator';

import {colors, mainFont, navBarHeight, opacity} from 'styles/shared';

const StyledTotalContainer = styled(Container)`
  padding: 0;
  top: calc(${navBarHeight} + 3rem);
  box-shadow: 0 0.4rem 2rem ${opacity(0.12)};
  ${({ sticky }) => sticky && `
    position: sticky;
  `}
`;

const StyledReportContainer = styled.div`
  background-color: ${colors.orange_main};
  border-end-end-radius: 0.8rem;
  border-end-start-radius: 0.8rem;
  display: flex;
  flex-direction: ${props => props.inspectionButtonVisible ? 'row' : 'column'};
  justify-content: ${props => props.inspectionButtonVisible ? 'space-around' : 'center'};
  align-items: center;
  text-align: center;
  padding: 2rem 1.6rem;
`;

const StyledReportButton = styled(Button)`
  background-color: ${colors.white};
  color: ${colors.orange_main};
`;

const StyledText = styled.div`
  ${mainFont};
  color: ${colors.white};
  margin-bottom: 3rem;
`;

interface Props {
  rateIsUpdating: boolean;
  rate?: Rate;
  domestic: boolean;
  usTerritories: boolean;
  shipmentId: string;
  ratesError?: string;
  currentStep?: string;
  inspectionId?: string;
  sticky: boolean;
  pageId?: string;
}

function ShipmentTotal({rateIsUpdating, rate, domestic, usTerritories, shipmentId, ratesError, currentStep, inspectionId, sticky, pageId}: Props) {
  const dispatch = useDispatch();

  const user = useSelector((state: AppState) => state.user.current as User);
  const inspectionButtonVisible: boolean = (user.isSuperAdmin && inspectionId) ? true : false;

  const [reportModalVisible, setReportModalVisible] = useState(false);

  function toggleReportModal() {
    setReportModalVisible(!reportModalVisible);
  }

  function contactCustomerSupport() {
    dispatch(
      commonActions.infoAction({
        type: 'info',
        text: 'Customer Support',
        closeTitle: 'Close',
        close: () => {}
      })
    );
  }

  async function sendQuoteReport(message) {
    let data = await supportService.getSupportRequestDto(shipmentId, rate?.id, currentStep);
    data.message = message;

    let isSuccess: any = await dispatch(commonActions.sendToSupport(data, false));

    if (!isSuccess) return;

    toggleReportModal();

    uiHelper.showNotice(MESSAGE.QUOTE_REPORT);
  }

  function renderCustomerSupportButton() {
    return (
      <StyledReportButton autoWidth onClick={contactCustomerSupport}>
          Contact Customer Support
      </StyledReportButton>
    );
  }

  function renderCSRReportButton() {
    return (
      <StyledReportButton onClick={toggleReportModal}>
        Report Problem
      </StyledReportButton>
    );
  }

  function renderUSTerritoriesWarning() {
    return (
      <>
        <StyledText>
          <strong>US Territories</strong> <br /> US Territory rates are not available online.
          {!user.isCSR && 'Please contact our Customer Service team to receive your quote.'}
        </StyledText>

        {!user.isCSR ? renderCustomerSupportButton() : renderCSRReportButton()}
      </>
    );
  }

  function renderBadRateWarning() {
    return (
      <>
        <StyledText>
          <strong>Something Went Wrong</strong> <br /> We are currently unable to display your quote, but we are working
          to fix it. You can contact Customer Service to get your quote immediately!
        </StyledText>

        {renderCustomerSupportButton()}
      </>
    );
  }

  function renderReportContainer(isIncompleteRate: boolean) {
    let componentToRender: JSX.Element | null = null;

    if (isIncompleteRate) {
      componentToRender = renderBadRateWarning();
    }

    if (usTerritories) {
      componentToRender = renderUSTerritoriesWarning();
    } else if (user.isCSR) {
      componentToRender = renderCSRReportButton();
    }

    if (!componentToRender) return null;

    return (
      <StyledReportContainer inspectionButtonVisible={inspectionButtonVisible}>
        {componentToRender}
        {inspectionButtonVisible && renderInspectionButton()}
      </StyledReportContainer>
    );
  }

  function renderInspectionButton() {
    return (
      <StyledReportButton onClick={openInspectionPage}>
        Inspect Quote
      </StyledReportButton>
    );
  }

  function openInspectionPage() {
    window.open(navigationHelper.getInspectUrl(inspectionId), '_blank');
  }

  function render() {
    const isIncompleteRate = rate ? rateService.isIncompleteRate(rate) : false;

    return (
      <StyledTotalContainer sticky={sticky}>
        <ProgressIndicator hidden={!rateIsUpdating} />

        <TotalSum
          rate={rate}
          error={ratesError}
          isDomestic={domestic}
          isUSTerritories={usTerritories}
          isIncompleteRate={isIncompleteRate}
          pageId={pageId}
        />

        {renderReportContainer(isIncompleteRate)}

        {reportModalVisible && (
          <ReportQuoteModal visible={reportModalVisible} close={toggleReportModal} send={sendQuoteReport} />
        )}
      </StyledTotalContainer>
    );
  }

  return render();
}

export default ShipmentTotal;
